

<template>
  <div>
  <Plotly :data="data" :layout="layout" :display-mode-bar="false"></Plotly>
</div>
</template>
 
<script>
import { Plotly } from 'vue-plotly'

export default {
  name: 'LineChart',
  props: {
    graphData: {}
  },
  components: {
    Plotly
  },
  data() {

    let livexData = this.graphData.livePrices.data.livex;
    let tempDataX = [];
    let tempDataY = [];

    livexData.forEach(element => {
      tempDataX.push(element.date);
      tempDataY.push(element.price);

    });
    return {
      data: [{
        x: tempDataX,
        y: tempDataY,
        type: 'scatter',
        mode: 'lines+markers',
        marker: {
          color: 'black'
        },
        line: { width: 1 }

      }],
      layout: {
        font: {
          family: 'Montserrat',
          size: 12,
          weight: 800,
          color: 'black'
        },
        title: "",
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        xaxis: {
          autorange: true,
          showgrid: false,
          zeroline: true,
          tickangle: 45,
          ticks: '',
        },
        yaxis: {
          autorange: true,
          showgrid: false,
          zeroline: true,
          ticks: '',
        },

      }
    }
  }
}
</script>
 
<style>

</style>
