<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
    <div class="vx-col">
      <div class="vx-row no-gutter justify-center items-center ">
        <div class="vx-col lg:block ">
          <center>
            <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
          </center>
        </div>

          <div class="vx-col mx-auto lg:w-3/4 pagemax-full">


            <span id="download_loader" v-if="!$store.state.portfolio"></span>

            <div v-if="$store.state.portfolio">


              <div v-for="investment in $store.state.portfolio" v-bind:key="investment.id">
                <div v-for="item in investment.items" v-bind:key="item.id">

                  <div v-if="(item.wine_id == $route.params.id && investment.id == $route.params.orderId)" class="">
                    <!-- {{$store.state.portfolio}} -->

                    <div v-for="total in getTotals" v-bind:key="total.currency" class="">
                      <div class=" float-right">
                        <span class="oeno_portfolio_total_total float-right ">{{ currencyFormatter(getPrice(investment,
                            item))
                        }} {{ investment.currency }}</span>
                        <br />
                        <span :class="[
                          priceUp(investment, item)
                            ? 'oeno_portfolio_price_up float-right'
                            : 'oeno_portfolio_price_down float-right',
                        ]">{{ getChange(investment, item, true) }}</span>
                      </div>
                    </div>

                    <button type="button" @click="closeButton()"
                      class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span class="sr-only">Close menu</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>


                    <div class="flex justify-center  ">
                      <img :src="wineImage(item)" class="oeno_portfolio_wineimage w-1/6 h-1/6	"  onerror="this.style.display='none'"/>
                    </div>
                    <!-- Display line chart only if livex chart data available -->
                    <LineChartPlotly v-if="(item.livePrices.data.livex.length > 0)" class="w-auto " :graphData="item" />


                    <div class="col-span-3 mt-10">
                      <span class="text-3xl font-bold oeno_portfolio_title">{{
                          item.wines.name
                      }}</span><br />
                      <span class="text-xl font-medium font-Montserrat">{{
                          winary(item)
                      }}</span><br />
                      <span class="font-bold font-Montserrat">{{
                          showquantity(item)
                      }}</span>
                    </div>
                    <div class="col-span-5">
                      <hr class="oeno_divider_sm" />
                    </div>
                    <div class="col-span-3 ">
                      <span class="priceDetails-sm font-bold">Purchase Price</span>
                      <span class="priceDetails-sm float-right">{{ currencyFormatter(getPurchasePrice(item))
                      }} {{ getCurrency(investment, item) }}</span>

                    </div>
                    <br />
                    <div class="col-span-3 ">
                      <span class="priceDetails-sm font-bold">Quantity</span>
                      <span class="priceDetails-sm float-right">{{ getQuantity(item)
                      }}</span>
                    </div>
                    <br />
                    <div>
                      <div class="col-span-5">
                        <hr class="oeno_divider_sm" />
                      </div>
                      <br />
                      <div class="col-span-3 ">
                        <span class="priceDetails-sm font-bold">Current Price</span>
                        <span class="priceDetails-sm float-right">{{ currencyFormatter(getPrice(investment,
                            item))
                        }} {{ getCurrency(investment, item) }}</span>
                      </div>
                      <br />
                      <div class="col-span-3 ">
                        <span class="priceDetails-sm font-bold">Profit</span>
                        <span :class="[
                          priceUp(investment, item)
                            ? 'oeno_portfolio_price_up priceDetails-sm  float-right'
                            : 'oeno_portfolio_price_down priceDetails-sm  float-right',
                        ]">{{ currencyFormatter(getChange(investment, item)) }}</span>
                      </div>

                      <br />
                      <div class="col-span-3 ">
                        <span class="priceDetails-sm font-bold">Item Profit</span>
                        <span :class="[
                          priceUp(investment, item)
                            ? 'oeno_portfolio_price_up priceDetails-sm float-right'
                            : 'oeno_portfolio_price_down priceDetails-sm float-right',
                        ]">{{ currencyFormatter(itemProfit(item).toFixed(2)) }}</span>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from "moment";
import LineChartPlotly from '../../../components/LineChartPlotly.vue';

export default {
  data() {

    return {
      getTotalPrice: "",
      getTotalPriceDiff: ""
    };
  },
  components: {
    LineChartPlotly
  },
  mounted() {

  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    getUserBroker() {
      var broker = "";
      if (this.$store.state.user) {
        broker =
          this.$store.state.user.brokerName +
          " " +
          this.$store.state.user.brokerEmail;
      }
      return broker;
    },
    getTotals() {
      const values = [];

      this.$store.state.portfolio.forEach((investment) => {
        investment.items.forEach((item) => {
          var itemPrice = this.getValuesForItem(investment, item);

          var pos = -1;
          for (let index = 0; index < values.length; index++) {
            if (values[index].currency == itemPrice.currency) {
              pos = index;
            }

          }

          if (pos > -1) {

            values[pos].total = values[pos].total + parseFloat(itemPrice.total);
            values[pos].changePrice = values[pos].changePrice + parseFloat(itemPrice.changePrice);

          } else {

            values.push({
              currency: itemPrice.currency,
              changePrice: parseFloat(itemPrice.changePrice),
              total: itemPrice.total,
            });

          }

        });
      });
      return values;
    },
  },
  methods: {
    getQuantity(item) {
      return (item.quantity * item.wines.units_per_case);
    },
    getPurchasePrice(item) {
      return (item.bottle_price * item.quantity *
        item.wines.units_per_case);
    },
    itemProfit(item) {

      return item.livePrices.data['price-average'] - item.bottle_price;
    },
    closeButton() {
      console.log("check");
      history.go(-1)
    },

    winary(wine) {
      var winary = "n/a";
      var vintage = "";
      var size = "";

      if (wine.wines) {
        if (wine.wines.winery) {
          vintage = wine.wines.vintage;
          if (wine.wines.winery.name) {
            winary = wine.wines.winery.name;
          }
          if (wine.wines.size.name) {
            size = wine.wines.size.name;
          }
        }
      }

      winary = winary + " " + vintage;

      if (size != "") {
        winary = winary + " " + size + "ML";
      }

      return winary;
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    wineImage(wine) {
      var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;

      if (wine.wines) {
        if (wine.wines.images) {
          if (wine.wines.images[0]) {
            wineimageurl =
              process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
          }
        }
      }

      return wineimageurl;
    },

    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }

      var total =
        parseInt(
          parseFloat(
            wine.quantity *
            wine.wines.units_per_case *
            wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

      var buytotal =
        wine.quantity * wine.wines.units_per_case * wine.bottle_price;

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc = ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
      };
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);
      this.getTotalPrice = priceValues.total;
      return this.getTotalPrice;
    },

    getCurrency(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.currency;
    },

    getChange(investment, wine, showperc = false) {
      var priceValues = this.getValuesForItem(investment, wine);
      if (showperc) {
        return this.currencyFormatter(priceValues.changePrice) + " ( " + this.currencyFormatter(priceValues.changePerc) + " %)";
      } else {
        return this.currencyFormatter(priceValues.changePrice);
      }
    },

    getChangeTotal(total) {

      var changePerc = (total.changePrice / (total.total - total.changePrice)) * 100;

      return total.changePrice + " ( " + changePerc.toFixed(2) + " %)";
    },

    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },

    currencyFormatter(value) {
      const options = {
        significantDigits: 2,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        symbol: "",
      };

      if (typeof value !== "number") value = parseFloat(value);
      value = value.toFixed(options.significantDigits);

      const [currency, decimal] = value.split(".");
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}${options.decimalSeparator}${decimal}`;
    },
  },
};
</script>

<style>
#download_loader {
  width: 100px;
  height: 100px;
  background-image: url(/dotloader.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin: auto;
  background-size: 100%;
}
</style>
